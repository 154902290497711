const light = Object.freeze({
    primary: '#d72a80',
    secondary: '#133339',
    navBackground: '#FFFFFF',
    appBackground: '#F5F5F5',
    inputBackground: '#FFFFFF',
    logoColor: '#d72a80',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
})

const dark = Object.freeze({
    primary: '#d72a80',
    secondary: '#133339',
    navBackground: '#2C2C2C',
    appBackground: '#1B1B1B',
    inputBackground: '#222222',
    logoColor: '#FFFFFF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
})

const props = Object.freeze({
    headerLogoWidth: 150,
    headerLogoSrc: require('@/assets/custom/images/headers/living-header.png')
})

module.exports = Object.freeze({
    light,
    dark,
    props
})

