<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-260 0 736 264">
    <path :fill="fillColor" d="M125.4,152.8c-23.5-7.7-37.9-12.9-37.9-30.3c0-15.2,12.3-24.1,28.2-24.1c10.9,0,19.1,3.3,24.9,10.3
	c4.7,5.6,5.6,11.8,9.4,17.6c6.5,9.8,16.2,9.3,21.6,5c5.9-4.8,7.7-15-0.7-25.2c-11.2-13.6-34-19.9-50.7-19.9
	c-38,0-55.8,20.4-55.8,45.8c0,24.3,17.1,38.1,44.7,46.9c27.6,8.2,47.6,12.7,47.6,36.2c0,16.1-12.4,29-37.2,29
	c-19.1,0-35.4-8.1-46.1-34.8c-1.2-3.4-2.5-4.4-4.8-4.4h-5.8v39.9c19,7.2,36.1,11.9,52.7,11.9c41.9,0,64.8-18.1,64.8-50.1
	C180.3,175.3,156.9,163.2,125.4,152.8L125.4,152.8z M196,65.1V52.4L105.2,5.9L7.4,64.7v189.6h20V76L106,28.7l80.7,41.3
	C191.5,72.6,196,69,196,65.1L196,65.1z"/>
  </svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
    sideLength: {
      type: String
    },

  }
}
</script>
